<template>
	<main :data-test="$route.path">
		<header>
			<pageHeader1image v-bind="pageHeader">
				<div class="flex flex-col tablet:flex-row space-y-8 tablet:space-y-0 tablet:space-x-16">
					<a class="w-196 bg-white hover:bg-goGrayBrighter active:bg-goGrayBrighter shadow hover:shadow-sm active:shadow-sm rounded-full transition-colors" target="_blank" rel="noopener" href="https://apps.apple.com/nz/app/apple-store/id1052970337">
						<img src="/images/blank.svg" data-src="/images/button-appleStore-dark.svg" decoding="async" class="lazyload" width="164" height="44" alt="GO Rental's Apple store" />
					</a>
					<a class="w-196 bg-white hover:bg-goGrayBrighter active:bg-goGrayBrighter shadow hover:shadow-sm active:shadow-sm rounded-full transition-colors" target="_blank" rel="noopener" href="https://play.google.com/store/apps/details?id=com.gorentals.mobile&referrer=utm_source%3Dwebsite%26utm_medium%3Dgo%2520app%2520page%26anid%3Dadmob">
						<img src="/images/blank.svg" data-src="/images/button-googlePlay-dark.svg" decoding="async" class="lazyload" width="164" height="44" alt="GO Rental's Google play" />
					</a>
				</div>
			</pageHeader1image>
		</header>

		<div class="py-16 tablet:py-48 desktop:py-56 space-y-56 tabletWide:space-y-112">
			<LazyHydrate when-visible>
				<layoutSection>
					<layoutPost type="wide">
							<header>
								<h2 class="font-bold text-32 desktop:text-48 text-goPink">Access your <span class="whitespace-nowrap">GO Rentals</span> <span class="whitespace-nowrap">booking any time</span></h2>
							</header>
							<p class="text-18 desktop:text-22 text-goGrayDarker">With this awesome app, you will have us in the palm of your hands. Available for iPhone or Android, the app is the fastest and most hassle-free way to access your account and rental car booking information. All of your reservations can be viewed quickly including pickup and drop off locations, vehicle model and features, additional drivers and insurance <span class="whitespace-nowrap">coverage selected.</span></p>
					</layoutPost>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection>
					<div class="p-16 tablet:p-0 grid grid-cols-12 gap-y-32 gap-x-8 tablet:gap-16">
						<review	v-bind="reviews[0]"		class="								col-span-6  tablet:col-start-1 tablet:row-start-1					tabletWide:col-start-1 tabletWide:row-start-1 tabletWide:col-span-2 desktop:col-start-1  desktop:col-span-3" />
						<review	v-bind="reviews[1]"		class="								col-span-6  tablet:col-start-7 tablet:row-start-2					tabletWide:col-start-7 tabletWide:row-start-1 tabletWide:col-span-2 desktop:col-start-7  desktop:col-span-3" />
						<feedback v-bind="feedbacks[0]"	class="bg-goGrayBrighter rounded-6	col-span-12 tablet:col-start-7 tablet:row-start-1 tablet:col-span-6	tabletWide:col-start-3 tabletWide:row-start-1 tabletWide:col-span-4 desktop:col-start-4  desktop:col-span-3" />
						<feedback v-bind="feedbacks[1]"	class="bg-goGrayBrighter rounded-6	col-span-12 tablet:col-start-1 tablet:row-start-2 tablet:col-span-6	tabletWide:col-start-9 tabletWide:row-start-1 tabletWide:col-span-4 desktop:col-start-10 desktop:col-span-3" />
					</div>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post-reversed">
					<template #main>
						<layoutPost type="center">
							<header>
								<h3 class="font-bold text-32 tablet:text-40 desktop:text-48">Save your profile. Never type it again</h3>
							</header>
							<p>Create a GO Rentals profile to make the rental car process simple. There is nothing worse than entering your details for a quote, then again for a booking, and again for pickup and requesting a shuttle. GO Rentals want to remember and value our customers.</p>
							<p>With our app, there is no need to waste time! You can update your personal info, contact details, and driver's licence to save you from entering it more than once.<br>It'll also make your next booking that much quicker.</p>
							<p>Everything you need for a super smooth GO Rentals experience, all in the palm of your hand.</p>
						</layoutPost>
					</template>

					<template #aside>
						<goPicture
							class="w-full h-full"
							alt="Image looking down on a female using the GO Rentals App to make a booking"
							:mobile="{ src: '/images/App_Feature_1.jpg', width: 375, height: 250 }"
							:tablet="{ src: '/images/App_Feature_1.jpg', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/App_Feature_1.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/App_Feature_1.jpg', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/App_Feature_1.jpg', width: 656, height: 438 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post">
					<template #main>
						<layoutPost type="left">
							<header>
								<h3 class="font-bold text-32 tablet:text-40 desktop:text-48">Check in faster & skip the queue</h3>
							</header>
							<p>Everything we do at GO Rentals is to help you on your journey so you can travel and see more beautiful country. That's why we have introduced the fastest check-in possible.</p>
							<p>Say goodbye to paperwork and say hello to an environmentally friendly way to check in via the app and sign the waiver in the mobile version too. You'll arrive and go straight to the key collection. Save time, to spend it doing what you love.</p>
						</layoutPost>
					</template>

					<template #aside>
						<goPicture
							class="w-full h-full"
							alt="Image of a GO Rentals team member handing over the keys through the window for a new rental car to a smiling customer"
							:mobile="{ src: '/images/App_Feature_2.jpg', width: 375, height: 250 }"
							:tablet="{ src: '/images/App_Feature_2.jpg', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/App_Feature_2.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/App_Feature_2.jpg', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/App_Feature_2.jpg', width: 656, height: 438 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post-reversed">
					<template #main>
						<layoutPost type="center">
							<header>
								<h3 class="font-bold text-32 tablet:text-40 desktop:text-48">Order a shuttle. Get picked up <span class="whitespace-nowrap">in minutes</span></h3>
							</header>
							<p>Changing from plane to car has never been smoother. The GO Rentals experience can start as soon as you get off the runway. Simply request your airport pick-up at the touch of a button & we'll see you soon. Thanks to real-time updates, you can even track your shuttle's arrival.</p>
						</layoutPost>
					</template>

					<template #aside>
						<goPicture
							class="w-full h-full"
							alt="Close up of a person holding a mobile phone with instructions of where to catch the shuttle via the GO Rentals App"
							:mobile="{ src: '/images/App_Feature_3.jpg', width: 375, height: 250 }"
							:tablet="{ src: '/images/App_Feature_3.jpg', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/App_Feature_3.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/App_Feature_3.jpg', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/App_Feature_3.jpg', width: 656, height: 438 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post">
					<template #main>
						<layoutPost type="left">
							<header>
								<h3 class="font-bold text-32 tablet:text-40 desktop:text-48">Control your Tesla</h3>
							</header>
							<p>Technology is just awesome! If you have a Tesla rental car booking, you can operate a lot of features using the GO app. With just a press of a button you can:</p>
							<ul class="text-14 desktop:text-16">
								<li class="bg-iconChevron pl-32">
									Monitor your Tesla's battery level and location all through <span class="whitespace-nowrap">our GO app</span>
								</li>
								<li class="bg-iconChevron pl-32">
									Lock and unlock the car
								</li>
								<li class="bg-iconChevron pl-32">
									Open the trunk & frunk (front trunk)
								</li>
								<li class="bg-iconChevron pl-32">
									Flash the lights
								</li>
								<li class="bg-iconChevron pl-32">
									Honk the horn
								</li>
							</ul>
						</layoutPost>
					</template>

					<template #aside>
						<goPicture
							class="w-full h-full"
							alt="GO Rentals Tesla Model Y"
							:mobile="{ src: '/images/Go_Tesla_model_y_Specs_0.jpg', width: 375, height: 250 }"
							:tablet="{ src: '/images/Go_Tesla_model_y_Specs_0.jpg', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/Go_Tesla_model_y_Specs_0.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/Go_Tesla_model_y_Specs_0.jpg', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/Go_Tesla_model_y_Specs_0.jpg', width: 656, height: 438 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection type="post-reversed">
					<template #main>
						<layoutPost type="center">
							<header>
								<h3 class="font-bold text-32 tablet:text-40 desktop:text-48">Make Another Booking</h3>
							</header>
							<p>We want you to love GO Rentals so much that we become a part of your road trip family. It is super easy to book your future car rentals through the app, see all of our great vehicles and convenient locations and auto-populate your rental details. Take us along for your next trip!</p>
						</layoutPost>
					</template>

					<template #aside>
						<goPicture
							class="w-full h-full"
							alt="Booking process in the GO Rentals App"
							:mobile="{ src: '/images/App_Feature_5.jpg', width: 375, height: 250 }"
							:tablet="{ src: '/images/App_Feature_5.jpg', width: 456, height: 304 }"
							:tabletWide="{ src: '/images/App_Feature_5.jpg', width: 220, height: 330 }"
							:desktop="{ src: '/images/App_Feature_5.jpg', width: 576, height: 384 }"
							:desktopWide="{ src: '/images/App_Feature_5.jpg', width: 656, height: 438 }"
						/>
					</template>
				</layoutSection>
			</LazyHydrate>

			<LazyHydrate when-visible>
				<layoutSection>
					<layoutPost type="wide">
						<header>
							<h3 class="font-bold text-20 tablet:text-24 text-goPink">Download now <br class="tablet:hidden" />for iOS & Android</h3>
						</header>

						<div class="flex flex-col tablet:flex-row space-y-8 tablet:space-y-0 tablet:space-x-16">
							<a class="w-196 bg-goBlack hover:bg-black active:bg-black shadow hover:shadow-sm active:shadow-sm rounded-full transition-colors" target="_blank" rel="noopener" href="https://apps.apple.com/nz/app/apple-store/id1052970337">
								<img src="/images/blank.svg" data-src="/images/button-appleStore-light.svg" decoding="async" class="lazyload" width="164" height="44" alt="GO Rental's Apple store" />
							</a>
							<a class="w-196 bg-goBlack hover:bg-black active:bg-black shadow hover:shadow-sm active:shadow-sm rounded-full transition-colors" target="_blank" rel="noopener" href="https://play.google.com/store/apps/details?id=com.gorentals.mobile&referrer=utm_source%3Dwebsite%26utm_medium%3Dgo%2520app%2520page%26anid%3Dadmob">
								<img src="/images/blank.svg" data-src="/images/button-googlePlay-light.svg" decoding="async" class="lazyload" width="164" height="44" alt="GO Rental's Google play" />
							</a>
						</div>
					</layoutPost>
				</layoutSection>
			</LazyHydrate>
		</div>
	</main>
</template>

<script>
import layoutSection from "~/components/layoutSection.vue"
import layoutPost from "~/components/layoutPost.vue"
import feedback from "~/components/feedback.vue"
import review from "~/components/review.vue"

export default {
	components: {
		layoutSection,
		layoutPost,
		review,
		feedback,
		pageHeader1image: () => import("~/components/pageHeader1image.vue"),
	},
	metaInfo: {
		title: "Download our App - Rental Car App - GO Rentals",
		meta: [
			{
				vmid: "description",
				name: "description",
				content: "The GO Rentals app allows you to manage your booking, book a shuttle and reserve your next trip with us. Download for Apple or Android now.",
			},
			{ vmid: "ogtitle", property: "og:title", content: "Download our App - Rental Car App - GO Rentals" },
			{ vmid: "ogurl", property: "og:url", content: "https://www.gorentals.co.nz/app/" },
		],
		link: [{ rel: "canonical", href: "https://www.gorentals.co.nz/app/" }],
	},
	async asyncData(context) {
		await context.store.dispatch("reviews/fetchCompany");
		const reviews = context.store.getters['reviews/byPlatforms'](["Apple App Store", "Google Play"])

		return {
			reviews,
		}
	},
	computed: {
		pageHeader() {
			return {
				title: "GO Rentals <br class='tabletWide:hidden' />Car App",
				headline: "We’re changing the way we do car rental, so you can easily manage it at your fingertips. <br class='hidden desktop:unset' />Book a car, order a shuttle and check in faster with your <span class='whitespace-nowrap'>mobile app.</span>",
				breadcrumb: { theme: "light", crumbs: [{ href: "/", text: "Home" }, { text: "GO App" }] },
				picture: {
					alt: "Image from the interior of a GO Rentals hire car heading down the winding slopes of a South Island mountain",
					mobile: { src: "/images/App_Hero_Mobile.jpg", width: 375, height: 495 },
					tablet: { src: "/images/App_Hero_Tablet.jpg", width: 720, height: 720 },
					tabletWide: { src: "/images/App_Hero_Tablet_Wide.jpg", width: 976, height: 696 },
					desktop: { src: "/images/App_Hero_Desktop.jpg", width: 1384, height: 718 },
					desktopWide: { src: "/images/App_Hero_Desktop_Wide.jpg", width: 1864, height: 718 },
				},
			}
		},
		feedbacks() {
			return [
				{
					text: "Just arrived in Christchurch. We ordered a shuttle through the App and were picked up immediately. Our driver was friendly and courteous. Depot check-in was a breeze.",
					avatar: "/images/Reviews_Helen.jpg",
					alt: "Image of Helen from Australia next to review of GO Rentals",
					name: "Helen",
					detail: "New Zealand",
				},
				{
					text: "Excellent friendly service and super easy pick up and drop-off no drawn out paperwork all done with an app on my phone. The shuttle drivers are awesome too.",
					avatar: "/images/Reviews_Malcolm.jpg",
					alt: "Image of Malcolm from New Zealand next to a review of GO Rentals",
					name: "Malcolm",
					detail: "New Zealand",
				},
			]
		}
	}
};
</script>
